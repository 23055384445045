const menuItemsService = [
  {
    title: "Restaurants",
    link: "/restaurants",
    icon: "mdi-silverware-fork-knife",
    active: true,
  },
  { 
    title: "Hoteles", 
    link: "/hotels", 
    icon: "mdi-bed", 
    active: true 
  },
  {
    title: "Transport",
    link: "/transportations",
    icon: "mdi-taxi",
    active: true,
  },
  {
    title: "Leisures",
    link: "/leisures",
    icon: "mdi-sticker-emoji",
    active: true,
  },
  // { title: "Hotels", link: "/hotels", icon: "mdi-bed", active: true },
  { divider: true, inset: true },
  // {
  //   title: "Pharmacies",
  //   link: "/pharmaceutics",
  //   icon: "mdi-pharmacy",
  //   active: true,
  // },
  // {
  //   title: "Laboratories",
  //   link: "/laboratories",
  //   icon: "mdi-eyedropper",
  //   active: true,
  // },

  { title: "Vineyards", link: "/vineyards", icon: "mdi-tree", active: true },
  {
    title: "Recovery Houses",
    link: "/houses",
    icon: "mdi-home-outline",
    active: true,
  },
  {
    title: "Assisting Livings",
    link: "/assiting-livings",
    icon: "fi fi-ss-user-nurse",
    active: true,
  },
];

const menuItemsAbout = [
  {
    title: "About us",
    link: "/about",
    icon: "mdi-account-multiple",
    active: true,
  },
  {
    title: "Why Baja California",
    link: "/why-baja-california",
    icon: "mdi-city",
    active: true,
  },
  {
    title: "Advantages of Baja California",
    link: "/advantages-baja-california",
    icon: "mdi-city",
    active: true,
  },
  { title: "Contacts", link: "/contacts", icon: "mdi-email", active: true },
  // {
  //   title: "Alliances",
  //   link: "/alliances",
  //   icon: "mdi-share-variant",
  //   active: true,
  // },
];

const menuItemsDoctors = [
  {
    title: "Board certified doctors",
    link: "/doctors",
    icon: "mdi-doctor",
    active: true,
  },
  {
    title: "Board certified hospitals & clinics",
    link: "/hospitals-clinics",
    icon: "mdi-hospital-box",
    active: true,
  },
  {
    title: "Register",
    link: "https://retys.bajacalifornia.gob.mx/Portal/TyS/1637?organismoId=70",
    icon: "mdi-account-multiple",
    active: true,
    href: true
  },
];

const menuItemsLang = [
  {
    title: "English",
    link: "/under-construction", // #lang-english
  },
  {
    title: "Español",
    link: "/under-construction", // #lang-spanish
  },
];

const menuItemsVertical = [
  {
    icon: "mdi-medical-bag",
    title: "Renowned Medical Tourism",
    active: true,
    children: [
      {
        title: "Board certified doctors",
        link: "/doctors",
        icon: "mdi-doctor",
        active: true,
      },
      {
        title: "Board certified hospitals & clinics",
        link: "/hospitals-clinics",
        icon: "mdi-hospital-box",
        active: true,
      },
      {
        title: "Register",
        link: "https://retys.bajacalifornia.gob.mx/Portal/TyS/1637?organismoId=70",
        icon: "mdi-account-multiple",
        active: true,
      },
    ]
  },
  {
    icon: "mdi-tag",
    title: "Other services",
    active: true,
    children: [
      {
        title: "Restaurants",
        link: "/restaurants",
        icon: "mdi-silverware-fork-knife",
        active: true,
      },
      {
        title: "Leisures",
        link: "/leisures",
        icon: "mdi-sticker-emoji",
        active: true,
      },
      // { title: "Hoteles", link: "/hotels", icon: "mdi-bed", active: true },
      {
        title: "Pharmacies",
        link: "/pharmaceutics",
        icon: "mdi-pharmacy",
        active: true,
      },
      {
        title: "Laboratories",
        link: "/laboratories",
        icon: "mdi-eyedropper",
        active: true,
      },
      {
        title: "Transport",
        link: "/transportations",
        icon: "mdi-taxi",
        active: true,
      },
      {
        title: "Vineyards",
        link: "/vineyards",
        icon: "mdi-tree",
        active: true,
      },
      {
        title: "Recovery Houses",
        link: "/houses",
        icon: "mdi-home-outline",
        active: true,
      },
      {
        title: "Assisting Livings",
        link: "/assiting-livings",
        icon: "fi fi-ss-user-nurse",
        active: true,
        flaticon: true,
      },
    ],
  },
];

export { menuItemsService, menuItemsAbout, menuItemsLang, menuItemsVertical, menuItemsDoctors };
