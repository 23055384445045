var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],staticClass:"hidden-md-and-up",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-cart",attrs:{"icon":"","fab":"","dark":"","id":"btn-cart"}},'v-btn',attrs,false),on),[_c('v-img',{staticStyle:{"object-fit":"contain","max-width":"50%"},attrs:{"src":require("@/assets/images/cart.svg"),"alt":"cart"}}),(_vm.cart.length > 0)?_c('span',{staticClass:"cart-badge",staticStyle:{"font-size":"25px","font-weight":"bold","color":"#4caf4f","margin":"5px"}},[_vm._v(_vm._s(_vm.cart.length))]):_vm._e()],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.cart),function(product,index){return _c('v-list-item',{key:index,staticClass:"mb-2",attrs:{"router":"","to":_vm.generateDynamicRoute(product)}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":product.image}})],1),_c('v-list-item-content',{staticStyle:{"max-width":"400px"}},[_c('v-list-item-title',{staticClass:"mb-1"},[_c('strong',[_vm._v(_vm._s(`${product.category} - ${product.name}`))])]),_c('small',{staticClass:"mb-1"},[_c('b',[_vm._v(" Phone: ")]),_vm._v(" "+_vm._s(_vm._f("formatPhone")(product.profile.phone ? product.profile.phone : product.profile.phone1,"MX")))]),_c('small',{staticClass:"mb-1"},[_c('b',[_vm._v(" Schedules: ")]),_vm._v(" "+_vm._s(product.profile.servicehours))]),_c('small',{staticClass:"mb-1"},[_c('b',[_vm._v(" Reservation Date: ")]),_vm._v(" "+_vm._s(product.reservation.date))]),_c('small',{staticClass:"mb-1"},[_c('b',[_vm._v(" Reservation Hour: ")]),_vm._v(" "+_vm._s(product.reservation.hour))])],1),_c('v-list-item-icon',[_c('v-btn',{style:({
              marginTop: _vm.$vuetify.breakpoint.smAndDown ? '25%' : '10%',
            }),on:{"click":function($event){return _vm.removeItem(index)}}},[_c('v-icon',[_vm._v(" mdi-delete")])],1)],1)],1)}),_c('v-list-item',[(_vm.cart.length)?_c('v-btn',{attrs:{"block":"","color":"success","dark":""},on:{"click":_vm.openReservationForm}},[_vm._v(" Make a reservation ")]):_c('v-list-item-title',[_vm._v(" You have no reservations ")])],1)],2)],1),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.reservationFormDialog),callback:function ($$v) {_vm.reservationFormDialog=$$v},expression:"reservationFormDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Reservation Form")])]),_c('v-card-text',[_c('v-form',{ref:"reservationFormRef"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Name","rules":[
                  (v) => !!v || 'Name is required',
                  (v) =>
                    /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(v) ||
                    'Only letters and spaces allowed',
                ]},model:{value:(_vm.reservationForm.name),callback:function ($$v) {_vm.$set(_vm.reservationForm, "name", $$v)},expression:"reservationForm.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Last name","rules":[
                  (v) => !!v || 'Last name are required',
                  (v) =>
                    /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(v) ||
                    'Only letters and spaces allowed',
                ]},model:{value:(_vm.reservationForm.surnames),callback:function ($$v) {_vm.$set(_vm.reservationForm, "surnames", $$v)},expression:"reservationForm.surnames"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Phone","rules":[(v) => /^[0-9]+$/.test(v) || 'Only numbers allowed']},model:{value:(_vm.reservationForm.phone),callback:function ($$v) {_vm.$set(_vm.reservationForm, "phone", $$v)},expression:"reservationForm.phone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Email","rules":[
                  (v) => !!v || 'Email is required',
                  (v) => /.+@.+\..+/.test(v) || 'Invalid email format',
                ]},model:{value:(_vm.reservationForm.email),callback:function ($$v) {_vm.$set(_vm.reservationForm, "email", $$v)},expression:"reservationForm.email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"label":"Gender","items":['Male', 'Female', 'Nonbinary', 'Other'],"rules":[(v) => !!v || 'Gender is required']},model:{value:(_vm.reservationForm.gender),callback:function ($$v) {_vm.$set(_vm.reservationForm, "gender", $$v)},expression:"reservationForm.gender"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Origin","rules":[
                  (v) => !!v || 'Origin is required',
                  (v) =>
                    /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(v) ||
                    'Only letters and spaces allowed',
                ]},model:{value:(_vm.reservationForm.origin),callback:function ($$v) {_vm.$set(_vm.reservationForm, "origin", $$v)},expression:"reservationForm.origin"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":"Accompanying Guests","rules":[(v) => /^[0-9]+$/.test(v) || 'Only numbers allowed']},model:{value:(_vm.reservationForm.accompanying_guests),callback:function ($$v) {_vm.$set(_vm.reservationForm, "accompanying_guests", $$v)},expression:"reservationForm.accompanying_guests"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":"Stay Duration (Days)","rules":[(v) => /^[0-9]+$/.test(v) || 'Only numbers allowed']},model:{value:(_vm.reservationForm.stay_duration),callback:function ($$v) {_vm.$set(_vm.reservationForm, "stay_duration", $$v)},expression:"reservationForm.stay_duration"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"label":"Visit Purpose","items":['Medical', 'Pleasure', 'Business'],"rules":[(v) => !!v || 'Visit Purpose is required']},model:{value:(_vm.reservationForm.visit_purpose),callback:function ($$v) {_vm.$set(_vm.reservationForm, "visit_purpose", $$v)},expression:"reservationForm.visit_purpose"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeReservationForm}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.submitReservationForm}},[_vm._v("Send Reservation ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":2000},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snackBar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }