<template>
  <div class="container d-flex container--fluid pa-0 ma-0"
    style="align-items: center; justify-content: space-around; max-width: 100%; z-index: 99">
    <v-btn class="ma-1" color="black" height="70px !important" plain text to="/hospitals-clinics">
      <span>Hospitals and Clinics</span>
    </v-btn>
    <v-btn color="black" height="70px !important" plain text to="/medical-services">
      <!-- #services -->
      <span>Top Doctors</span>
    </v-btn>

    <v-btn color="black" height="70px !important" plain text to="/laboratories">
      <span>Laboratories</span>
    </v-btn>

    <v-btn color="black" height="70px !important" plain text to="/pharmaceutics">
      <span>Pharmacies</span>
    </v-btn>

    <!-- <v-btn color="black" height="70px !important" plain text to="/houses">
      <span>Recovery Houses</span>
    </v-btn> -->

    <!-- <v-btn color="black" height="70px !important" plain text to="/transportations">
      <span>Transport</span>
    </v-btn> -->
    <div class="d-flex align-start">
      <router-link to="/homepage">
        <v-img style="margin: 0 0 !important; width: 200px; height: auto" alt="TurismoBC Logo"
          class="shrink mt-1 hidden-sm-and-down" contain src="@/assets/images/logo-bht.png" />
      </router-link>
    </div>

    <v-btn color="black" height="70px !important" plain text to="/hotels">
      <span>Hotels</span>
    </v-btn>



    <v-menu class="hidden-md-and-up" offset-y open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="black" height="70px !important" plain text to="/other-services">
          <span>Other services</span>
        </v-btn>
      </template>

      <v-list v-model="selectedItemService" dense>
        <template v-for="(item, index) in itemsService">
          <v-divider v-if="item.divider" :key="index" :inset="item.inset">
          </v-divider>
          <v-list-item v-else :key="item.title" :to="item.link">
            <v-icon color="black" height="70px !important" v-text="item.icon"></v-icon>
            <v-list-item-action>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <v-menu class="hidden-md-and-up" offset-y open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="black" height="70px !important" plain text>
          <span>Medical tourism</span>
        </v-btn>
      </template>

      <v-list v-model="selectedItemAboutUs" dense>
        <template v-for="(item, index) in itemsAbout">
          <v-divider v-if="item.divider" :key="index" :inset="item.inset">
          </v-divider>
          <v-list-item v-else :key="item.title" :to="item.link">
            <v-icon color="black" height="70px !important" v-text="item.icon"></v-icon>
            <v-list-item-action>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <!-- <v-btn color="black" height="70px !important" plain text to="/restaurants">
      <span>Restaurants</span>
    </v-btn> -->

    <!-- <v-spacer></v-spacer> -->

    <!--v-btn color="black" height="70px !important" plain text to="/doctors">
      <span>All Doctors Certified</span>
    </!--v-btn-->

    <div class="d-flex" style="align-items: end">
      <router-link class="mt-auto mb-auto" to="/doctors">
        <v-img style="width: 50px; height: 50px" alt="Coepris" class="shrink mt-1 hidden-sm-and-down" contain
          src="@/assets/images/escudo_coepris.png" />
      </router-link>
      <!--v-btn color="black" height="70px !important" plain text to="/doctors">
        <span>Accredited Doctors</span>
      </!--v-btn-->
      <v-menu class="hidden-md-and-up" offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="black" height="70px !important" plain text>
            <span>Renowned Medical Tourism</span>
          </v-btn>
        </template>

        <v-list v-model="selectedItemServiceDoctor" dense>
          <template v-for="(item, index) in itemsDoctors">
            <v-divider v-if="item.divider" :key="index" :inset="item.inset">
            </v-divider>
            <v-list-item v-else-if="item.href" :key="item.link" to="#">
              <v-icon color="black" height="70px !important" v-text="item.icon"></v-icon>
              <v-list-item-action>
                <v-list-item-title v-text="item.title" @click="redirectTo(item.link)"></v-list-item-title>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-else :key="item.title" :to="item.link">
              <v-icon color="black" height="70px !important" v-text="item.icon"></v-icon>
              <v-list-item-action>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </div>
    <div class="d-flex flex-row align-center">
      <cart />
    </div>
  </div>
</template>

<script>
import {
  menuItemsService,
  menuItemsAbout,
  menuItemsLang,
  menuItemsDoctors
} from "@/layouts/app-layout/menu/MenuItems.js";
// import SearchBoxComponent from "@/components/search-box/SearchBoxComponent";
import Cart from "@/components/cart/Cart.vue";

export default {
  name: "HorizontalMenu",
  components: {
    // SearchBoxComponent,
    Cart,
  },
  data: () => ({
    selectedItemService: 1,
    selectedItemAboutUs: 1,
    selectedLang: 1,
    selectedItemServiceDoctor: 1,
    itemsService: menuItemsService,
    itemsAbout: menuItemsAbout,
    langItems: menuItemsLang,
    itemsDoctors: menuItemsDoctors,
  }),

  methods: {
    redirectTo(url){
      window.location.replace(url);
    }
  },

  mounted() {},
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}

.v-btn:not(.v-btn--round).v-size--default{
  padding: 0 5px !important;
}
</style>
